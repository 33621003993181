<template>
    <div class="container mt-4 mb-5" style="text-align: left">

        <div class="row mb-5">
            <div class="col-9">
                <h3>{{nome_curso}} </h3>
            </div>
            <div class="col" style="text-align: right">
                <b-button variant="outline-primary" style="margin-left: 10px;" to="/">Voltar</b-button>
            </div>
        </div>
        
        <!-- 1. Apresentação e justificativa -->
        <div class="row mb-2 mt-4">
            <h2>1. Apresentação e Justificativa</h2>
            <hr>
        </div>

        <!-- 1.3 Justificativa para oferta do curso" -->
        <div class="row mb-2">
            <itemInput code="item_1_3" :id_curso="id_curso_atual" titulo="1.3 Justificativa para oferta do curso" v-model="formulario_especifico.item_1_3" @valorEmitido="log"></itemInput>
            <!-- <richTextInput forms="formulario_especifico"  code="item_1_3" :id_curso="id_curso_atual" titulo="1.3 Justificativa para oferta do curso" v-model="formulario_especifico.item_1_3" @valorEmitido="log"></richTextInput> -->
        </div>

        <!-- 1.4. Relevância da oferta (evidenciar a necessidade de profissionais com a formação na localidade). -->
        <div class="row mb-4">
            <itemInput code="item_1_4" :id_curso="id_curso_atual" titulo="1.4. Relevância da oferta (evidenciar a necessidade de profissionais com a formação na localidade)" v-model="formulario_especifico.item_1_4" @valorEmitido="log"></itemInput>
            <!-- <richTextInput forms="formulario_especifico"  code="item_1_4" :id_curso="id_curso_atual" titulo="1.4. Relevância da oferta (evidenciar a necessidade de profissionais com a formação na localidade)" v-model="formulario_especifico.item_1_4" @valorEmitido="log"></richTextInput> -->
        </div>
        
        <!-- 1.5. Objetivos do Curso: -->
        <div class="row mb-2">
            <h4>1.5. Objetivos do Curso:</h4>
        </div>

        <!-- 1.5.1. Objetivo Geral.  -->
        <div class="row mb-2">
            <itemInput code="item_1_5_1" :id_curso="id_curso_atual" titulo="1.5.1. Objetivo Geral" v-model="formulario_especifico.item_1_5_1" @valorEmitido="log"></itemInput>
            <!-- <richTextInput forms="formulario_especifico"  code="item_1_5_1" :id_curso="id_curso_atual" titulo="1.5.1. Objetivo Geral" v-model="formulario_especifico.item_1_5_1" @valorEmitido="log"></richTextInput> -->
        </div>

        <!-- 1.5.2. Objetivos Específicos.  -->
        <div class="row mb-2">
            <itemInput code="item_1_5_2" :id_curso="id_curso_atual" titulo="1.5.2. Objetivos Específicos" v-model="formulario_especifico.item_1_5_2" @valorEmitido="log"></itemInput>
            <!-- <richTextInput forms="formulario_especifico"  code="item_1_5_2" :id_curso="id_curso_atual" titulo="1.5.2. Objetivos Específicos" v-model="formulario_especifico.item_1_5_2" @valorEmitido="log"></richTextInput> -->
        </div>
        
        <!-- 3. Perfil profissional de conclusão.  -->
        <div class="row mb-2 mt-4">
            <h2>3. Perfil profissional de conclusão</h2>
            <hr>
            <itemInput code="item_3" :id_curso="id_curso_atual" titulo="3. Perfil profissional de conclusão" v-model="formulario_especifico.item_3" @valorEmitido="log"></itemInput>
            <!-- <itemInput code="item_3" :id_curso="id_curso_atual" titulo="3. Perfil profissional de conclusão" v-model="formulario_especifico.item_3" @valorEmitido="log"></itemInput> -->
            <!-- <richTextInput forms="formulario_especifico" :id_curso="id_curso_atual" code="item_3" titulo="3. Perfil profissional de conclusão" v-model="formulario_especifico.item_3" @valorEmitido="log"></richTextInput> -->
        </div>
        
        <!-- 3.1 Habilidades e competências desenvolvidas na formação.  -->
        <div class="row mb-4">
            <itemInput code="item_3_1" :id_curso="id_curso_atual" titulo="3.1 Habilidades e competências desenvolvidas na formação" v-model="formulario_especifico.item_3_1" @valorEmitido="log"></itemInput>
            <!-- <richTextInput forms="formulario_especifico" :id_curso="id_curso_atual" code="item_3_1" titulo="3.1 Habilidades e competências desenvolvidas na formação" v-model="formulario_especifico.item_3_1" @valorEmitido="log"></richTextInput> -->
        </div>
        
        <!-- 4. Organização Curricular.  -->
        <div class="row mb-2 mt-4">
            <h2>4. Organização Curricular</h2>
            <hr>
        </div>
        
        <!-- 4.1. Representação gráfica da organização curricular e do itinerário formativo.  -->
        <div class="row mb-4">
            <h6><strong>4.1. Representação gráfica da organização curricular e do itinerário formativo</strong></h6>
            <uploadImgInput :curso_id="$route.params.id_firebase" item="item_4_1"></uploadImgInput>
        </div>

        <!-- 4.0 Imagem base64 -->
        <div  class="row mb-5 mt-5">
            <img style="width: 800px" v-if="formulario_especifico.item_4_0" :src="'data:image/png;base64,' + formulario_especifico.item_4_0"  alt="Red dot" />
            <itemInput code="item_4_0" :id_curso="id_curso_atual" titulo="Imagem em base64" v-model="formulario_especifico.item_4_0" @valorEmitido="log"></itemInput>
        </div>
        
        <!-- 4.2. Apresentação das Unidades Curriculares.  -->
        <div class="row mb-2">
            <h4>4.2. Apresentação das Unidades Curriculares</h4>
        </div>
        
        <div class="row mb-5">
            <unidadeCurricularInput code="item_4_2_1" :id_curso="id_curso_atual" titulo="4.2.1. Ementários das Unidades (repetir para cada Unidade)" v-model="formulario_especifico.item_4_2_1" @valorEmitido="log"></unidadeCurricularInput>
        </div>
        
        <!-- 4.3. Plano de realização do Estágio Curricular (somente Enf E radio) -->
        <div v-if="$route.params.id_firebase == 'jrk9n6dUdqfVyK2E0tXk' || $route.params.id_firebase == 'zpr9qHxS6XW0Wt1ua9eZ'" class="row mb-5 mt-5">
            <itemInput code="item_4_3" :id_curso="id_curso_atual" titulo="4.3. Plano de realização do Estágio Curricular" v-model="formulario_especifico.item_4_3" @valorEmitido="log"></itemInput>
            <!-- <richTextInput forms="formulario_especifico"  code="item_4_3" :id_curso="id_curso_atual" titulo="4.3. Plano de realização do Estágio Curricular " v-model="formulario_especifico.item_4_3" @valorEmitido="log"></richTextInput> -->
        </div>
        
        <!-- 4.10 Atividades práticas previstas -->
        <div class="row mb-5">
            <itemInput code="item_4_10" :id_curso="id_curso_atual" titulo="4.10 Atividades práticas previstas" v-model="formulario_especifico.item_4_10" @valorEmitido="log"></itemInput>
            <!-- <richTextInput forms="formulario_especifico"  code="item_4_10" :id_curso="id_curso_atual" titulo="4.10 Atividades práticas previstas" v-model="formulario_especifico.item_4_10" @valorEmitido="log"></richTextInput> -->
        </div>

         
    </div>
</template>

<script>
import firebase from "firebase/app";
import "firebase/analytics";
import "firebase/auth";
import 'firebase/database';
import 'firebase/firestore';
import uploadImgInput from '@/views/teste_nadir/components/upload_img.vue'
import unidadeCurricularInput from '@/views/teste_nadir/components/unidadeCurricularInput.vue'
import itemInput from '@/views/teste_nadir/components/itemInput.vue'
// import richTextInput from '@/views/teste_nadir/components/richTextInput.vue'


// Inicializando o Firebase
var app = firebase.app();
firebase.analytics();
var db = firebase.firestore(app);

export default {
    components: { 
        uploadImgInput,
        itemInput,
        unidadeCurricularInput,
        // richTextInput
    },
    data() {
        return {
            teste_real_time: [],
            responsavel: '',
            nome_curso: '',
            formulario_especifico: [],     
            user: null,
            id_curso_atual: this.$route.params.id_firebase
        }
    },
    computed: {
        soma_carga_unidades_curriculares: function(){
            let soma = 0
            if(this.formulario_especifico.item_4_2_1){
                this.formulario_especifico.item_4_2_1.forEach( x => soma = soma + parseInt(x.carga_horaria))
            }     
            return soma
        },

        num_unidades_curriculares: function(){
            let num = 0
            num = this.formulario_especifico.item_4_2_1.length
            return num
        }
    },
    methods: {
        log(value){
            console.log('Item atualizado', value)
            // Após o elemento concluir a inserção de dados no firebase, atualiza os dados
            this.atualizarDados()
        },

        atualizarDados(){
            db.collection("/nadir").doc(this.$route.params.id_firebase)
            .get()
            .then((doc) => {
                this.formulario_especifico = doc.data().formulario_especifico
                this.nome_curso = doc.data().nome_curso
                this.responsavel = doc.data().responsavel
                console.log(this.formulario_especifico)
            })
            .catch((error) => {
                console.log("Error getting documents: ", error);
            });
        },

        cadastrar(){
            db.collection("/nadir").doc(this.$route.params.id_firebase)
            .update({formulario_especifico: this.formulario_especifico})
            .then(() => {
                alert('Alterações salvas com sucesso')
                console.log("Document successfully updated!");
            })
            .catch((error) => {
                alert('Erro, verifique o console')
                console.error("Error updating document: ", error);
            });
            
        },

        addItem_4_2_1(){
            this.formulario_especifico.item_4_2_1.push(
                {
                    nome_unidade: '',
                    carga_horaria: '',
                    competencias: '',
                    habilidades: '',
                    atitudes: '',
                    praticas: '',
                    avaliacao: '',
                    bibliografia_basica: [],
                    bibliografia_complementar: []
                }
            )
        },

    },
    mounted() {
        console.log(this.formulario_especifico.item_4_1_1)
        if(this.$route.params.id_firebase){
            this.atualizarDados()
        } else {
            alert('Nenhum curso encontrado para o ID especificado')
        }
    }
}
</script>

<style scoped>
p{
    text-indent: 50px;
}
</style>