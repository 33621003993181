<template>
    <div class="container">
        <div class="row mt-2 mb-4">
            <!-- Título -->
            <h6><strong>4.2.1. Ementários das Unidades (repetir para cada Unidade)</strong></h6>

            <!-- Resumo -->
            <div class="row mt-2 mb-4">
            <!-- {{valor_formatado_unidades_tabela}} -->
                <b-table-simple v-for="semestre in valor_formatado_unidades_tabela" :key="semestre.id" bordered fixed outlined small responsive>
                    <b-thead head-variant="light">
                        <b-tr>
                            <b-th colspan="3">{{semestre.semestre_num}}° semestre</b-th>
                            <b-th colspan="1">Carga horária</b-th>
                            <b-th colspan="1">Modalidade</b-th>
                        </b-tr>
                    </b-thead>
                    <b-tbody>
                        <b-tr v-for="unidade in semestre.unidades" :key="unidade.id">
                            <b-td colspan="3" tbody-tr-class="light">{{unidade.nome_unidade}}</b-td>
                            <b-td colspan="1">{{unidade.carga_horaria}}</b-td>
                            <b-td colspan="1">{{unidade.modalidade}}</b-td>
                        </b-tr>
                        <b-tr>
                            <b-td colspan="3"><strong>Carga horaria do semestre:</strong></b-td>
                            <b-td colspan="2"><strong>{{sum_semestre_carga_horaria(semestre)}}</strong></b-td>
                        </b-tr>
                    </b-tbody>
                </b-table-simple> 


                <div class="row" style="border: 1px solid grey; border-radius: 7px; padding: 10px;">
                    <div class="col">
                        <strong>N° Unidades Curriculares Cadastradas: {{num_unidades_curriculares}}</strong>
                    </div>
                    <div class="col">
                        <strong>Carga Horária Total: {{soma_carga_unidades_curriculares}}</strong>
                    </div>
                </div>
                
            </div>

            <!-- Tabela -->
            <div v-for="(item,id_unidade) in value" :key="item.id_unidade" class="container">
                <div class="row mb-5" style="background-color: aliceblue; padding: 15px;">
                    <div class="row"> 
                        <h4><strong>{{item.nome_unidade}} | {{item.modalidade}} | Carga horária: {{item.carga_horaria}} horas | {{item.semestre}}° Semestre </strong>
                            <b-button @click="editUnidade(id_unidade)" variant="link" style="text-decoration: none"> 
                            <b-icon icon="pencil-square"></b-icon>Editar</b-button>
                        </h4>
                    </div>
                    <hr>

                    <!-- Ementa -->
                    <h5><strong>Ementa:</strong>
                        <b-button v-b-modal="'modal-edit-ementa'" @click="modalEmenta(id_unidade)" variant="link" style="text-decoration: none"> 
                        <b-icon icon="pencil-square"></b-icon>Editar</b-button>
                    </h5>
                    <div class="row" style="place-content: center; text-align: justify">
                        <p>{{item.ementa}}</p>

                    </div>
                    <hr class="mt-1mb-3">

                    <!-- competencias -->
                    <h5><strong>Competências:</strong></h5>
                    <div class="row" style="place-content: center;">
                        <div class="col-10 competencia-card mb-3" v-for="(competencia, id_competencia) in item.competencias" :key="competencia.id">
                            <h6><strong>Competência {{id_competencia+1}}: {{competencia.nome}}</strong>
                                <b-button @click="editCompetencia(id_unidade, id_competencia)" variant="link" style="text-decoration: none"> 
                                <b-icon icon="pencil-square"></b-icon>Editar</b-button>
                            </h6>
                            <hr>
                            <p><strong>Conhecimento:</strong> {{competencia.conhecimento}}</p>
                            <p><strong>Habilidade:</strong> {{competencia.habilidade}}</p>
                            <p><strong>Atitude:</strong> {{competencia.atitude}}</p>                  
                        </div>
                    </div>

                    <div style="text-align: center;">
                        <b-button class="mb-4" @click="modalNovaCompetencia(id_unidade)" variant="primary">Adicionar Competência</b-button>
                    </div>
                    
                    <!-- avaliacao -->
                    <h5><strong>Avaliação:</strong>
                        <b-button v-b-modal="'modal-edit-avaliacao'" @click="modalAvaliacao(id_unidade)" variant="link" style="text-decoration: none"> 
                        <b-icon icon="pencil-square"></b-icon>Editar</b-button>
                    </h5>
                    <div class="row" style="place-content: center; text-align: justify">
                        <p>{{item.avaliacao}}</p>

                    </div>
                    <hr class="mt-1 mb-3">

                    <!-- praticas -->
                    <h5><strong>Práticas:</strong>
                        <b-button v-b-modal="'modal-edit-praticas'" @click="modalPraticas(id_unidade)" variant="link" style="text-decoration: none"> 
                        <b-icon icon="pencil-square"></b-icon>Editar</b-button>
                    </h5>
                    <div class="row" style="place-content: center; text-align: justify">
                        <p>{{item.praticas}}</p>

                    </div>
                    <hr class="mt-1 mb-3">
                    
                    <div class="row">
                        <h5><strong>Bibliografia:</strong>
                            <b-button v-if="usuario_perfil !== 'conteudista'" @click="modalNovaBib(id_unidade)" variant="link" style="text-decoration: none"> 
                            <b-icon icon="pencil-square"></b-icon>Editar</b-button>
                        </h5>
                        <h6>Básica:</h6>
                        <ul><li class="ml-2" v-for="item in item.bibliografia_basica" :key="item.id">{{item}}</li></ul>
                        <h6>Complementar:</h6>
                        <ul><li class="ml-2" v-for="item in item.bibliografia_complementar" :key="item.id">{{item}}</li></ul>
                        
                    </div>
                    
                </div>
                
            </div>

            <!-- Botão Editar -->
            <div class="col" style="text-align: center;">
                <b-button @click="modalNovaUnidade()" variant="danger" style="text-decoration: none"> 
                <b-icon icon="plus-square"></b-icon> Adicionar Unidade Curricular</b-button>
            </div>

            <!-- Modal Adicionar Unidade Curricular -->
            <b-modal size="lg" id="modal-add-unidade" title="Unidade Curricular" @show="resetModalUnidade" @hidden="resetModalUnidade">
                <!-- {{valor_provisorio_unidade}} -->
                <b-form-group label="Nome da Unidade Curricular:" :label-for="'modal-input-'+code+'nome_unidade'">
                    <b-form-input :id="'modal-input-'+code+'nome_unidade'" v-model="valor_provisorio_unidade.nome_unidade" rows="3" max-rows="20"></b-form-input>
                </b-form-group>

                <b-form-group :label="'Carga horária: ('+ valor_provisorio_unidade.carga_horaria + ' horas)'" :label-for="'modal-input-'+code+'carga-horaria'">
                    <b-form-input :id="'modal-input-'+code+'carga-horaria'" v-model="valor_provisorio_unidade.carga_horaria" type="number" min="0"></b-form-input>
                </b-form-group>

                <b-form-group label="Semestre:" :label-for="'modal-input-'+code+'semestre'">
                    <b-form-select v-model="valor_provisorio_unidade.semestre" :options="options_semestres"></b-form-select>
                    <!-- <b-form-input :id="'modal-input-'+code+'semestre'" v-model="valor_provisorio_unidade.semestre" type="number" rows="3" max-rows="20"></b-form-input> -->
                </b-form-group>

                <b-form-group label="Modalidade:" :label-for="'modal-input-'+code+'modalidade'">
                    <b-form-select v-model="valor_provisorio_unidade.modalidade" :options="options_modalidade"></b-form-select>
                </b-form-group> 

                <template v-if="modal_unidade_edit" #modal-footer>
                    <b-button size="sm" variant="danger" @click="deleteEditUnidade()">
                        Deletar
                    </b-button>
                    <b-button size="sm" variant="primary" @click="salvarEditUnidade()">
                        Salvar
                    </b-button>  
                </template>
                <template v-else #modal-footer>
                    <b-button size="sm" variant="primary" @click="addUnidade()">
                        Adicionar
                    </b-button>  
                </template> 
            </b-modal>

            <!-- Modal Adicionar competência -->
            <b-modal size="lg" id="modal-add-competencia" :title="'Editar competência'" @show="resetModalCompetencia" @hidden="resetModalCompetencia">
                <!-- <h1>{{unidade_selecionada}}</h1>
                {{valor_provisorio_competencia}} -->
                <b-form-group label="Nome:" :label-for="'modal-input-'+code+'nome'">
                    <b-form-input :id="'modal-input-'+code+'nome'" v-model="valor_provisorio_competencia.nome"></b-form-input>
                </b-form-group>

                <b-form-group label="Conhecimento:" :label-for="'modal-input-'+code+'nome'">
                    <b-form-input :id="'modal-input-'+code+'nome'" v-model="valor_provisorio_competencia.conhecimento"></b-form-input>
                </b-form-group>

                <b-form-group label="Habilidade:" :label-for="'modal-input-'+code+'quantidade'">
                    <b-form-input :id="'modal-input-'+code+'quantidade'" v-model="valor_provisorio_competencia.habilidade"></b-form-input>
                </b-form-group>

                <b-form-group label="Atitude:" :label-for="'modal-input-'+code+'capacidade'">
                    <b-form-input :id="'modal-input-'+code+'capacidade'" v-model="valor_provisorio_competencia.atitude"></b-form-input>
                </b-form-group>  

                <template v-if="modal_competencia_edit" #modal-footer>
                    <b-button size="sm" variant="danger" @click="deleteEditCompetencia()">
                        Deletar
                    </b-button>
                    <b-button size="sm" variant="primary" @click="salvarCompetencia()">
                        Salvar
                    </b-button>  
                </template>
                <template v-else #modal-footer>
                    <b-button size="sm" variant="primary" @click="addCompetencia()">
                        Adicionar
                    </b-button>  
                </template> 
            </b-modal>

            <!-- Modal bibliografia -->
            <b-modal size="lg" id="modal-add-bibliografia" title="Adicionar Bibliografia" >
                <b-form-select v-model="tipo_bib_selecionado" :options="options_bibliografia" class="mb-3"></b-form-select>
                <!-- {{valor_provisorio_unidade}} | {{unidade_selecionada}} -->
                <b-form-tags v-model="valor_provisorio_unidade[tipo_bib_selecionado]" no-outer-focus class="mb-2">
                    <template v-slot="{ tags, inputAttrs, inputHandlers, addTag, removeTag }">
                        <b-input-group aria-controls="my-custom-tags-list">
                        <input
                            v-bind="inputAttrs"
                            v-on="inputHandlers"
                            placeholder="Para adicionar uma bibliografia, pressione ENTER e depois SALVAR"
                            class="form-control">
                        <b-input-group-append>
                            <b-button @click="addTag()" variant="primary">Add</b-button>
                        </b-input-group-append>
                        </b-input-group>
                        <ul
                        id="my-custom-tags-list"
                        class="list-unstyled d-inline-flex flex-wrap mb-0"
                        aria-live="polite"
                        aria-atomic="false"
                        aria-relevant="additions removals"
                        >
                        <!-- Always use the tag value as the :key, not the index! -->
                        <!-- Otherwise screen readers will not read the tag
                            additions and removals correctly -->
                        <b-card
                            v-for="tag in tags"
                            :key="tag"
                            :id="`my-custom-tags-tag_${tag.replace(/\s/g, '_')}_`"
                            tag="li"
                            class="mt-1 mr-1"
                            body-class="py-1 pr-2"
                        >
                            <strong>{{ tag }}</strong>
                            <b-button
                            @click="removeTag(tag)"
                            variant="link"
                            size="sm"
                            :aria-controls="`my-custom-tags-tag_${tag.replace(/\s/g, '_')}_`"
                            >remove</b-button>
                        </b-card>
                        </ul>
                    </template>
                </b-form-tags>

                <hr>
                
                <ul>
                    <li v-for="bib in bibliogafias_separadas_array" :key="bib.id">{{bib}}</li>
                </ul>

                <b-form-textarea v-model="array_bibi_provisorio" rows="3" max-rows="20">

                </b-form-textarea>
                <b-button size="sm" variant="primary" @click="separarPushBib()">Separar</b-button> 
           
                <template #modal-footer>
                    <b-button size="sm" variant="primary" @click="addBibliografia()">
                        Salvar
                    </b-button>  
                </template> 
            </b-modal>

            <!-- Modal Ementa -->
            <b-modal size="lg" id="modal-edit-ementa" title="" @show="resetModalEmenta" @hidden="resetModalEmenta">
                <b-form-group label="Ementa:" :label-for="'modal-input-'+code+'nome_unidade'">
                    <b-form-textarea :id="'modal-input-'+code+'nome_unidade'" v-model="valor_provisorio_ementa" rows="3" max-rows="20"></b-form-textarea>
                </b-form-group>
            
                <template #modal-footer>
                    <b-button size="sm" variant="primary" @click="editarEmenta()">
                        Salvar
                    </b-button>  
                </template> 
            </b-modal>

            <!-- Modal Avaliacao -->
            <b-modal size="lg" id="modal-edit-avaliacao" title="" @show="resetModalAvaliacao" @hidden="resetModalAvaliacao">
                <b-form-group label="Avaliação:" :label-for="'modal-input-'+code+'nome_unidade'">
                    <b-form-textarea :id="'modal-input-'+code+'nome_unidade'" v-model="valor_provisorio_avaliacao" rows="3" max-rows="20"></b-form-textarea>
                </b-form-group>
            
                <template #modal-footer>
                    <b-button size="sm" variant="primary" @click="editarAvaliacao()">
                        Salvar
                    </b-button>  
                </template> 
            </b-modal>

            <!-- Modal Praticas -->
            <b-modal size="lg" id="modal-edit-praticas" title="" @show="resetModalPraticas" @hidden="resetModalPraticas">
                <b-form-group label="Avaliação:" :label-for="'modal-input-'+code+'nome_unidade'">
                    <b-form-textarea :id="'modal-input-'+code+'nome_unidade'" v-model="valor_provisorio_praticas" rows="3" max-rows="20"></b-form-textarea>
                </b-form-group>
            
                <template #modal-footer>
                    <b-button size="sm" variant="primary" @click="editarPraticas()">
                        Salvar
                    </b-button>  
                </template> 
            </b-modal>



        </div>
    </div>
</template>

<script>
import firebase from "firebase/app";
import "firebase/analytics";
import 'firebase/firestore';

// Inicializando o Firebase
var app = firebase.app();
firebase.analytics();
var db = firebase.firestore(app);

export default {
    name: 'unidadeCurricularInput',
    props: ['titulo', 'code', 'value', 'id_campus', 'id_curso', 'forms'],
    data() {
        return {
            valor_provisorio_unidade: {
                nome_unidade: '',
                modalidade: '',
                carga_horaria: '',
                semestre: '',
                competencias: [],
                praticas: '',
                avaliacao: '',
                bibliografia_basica: '',
                bibliografia_complementar: ''
            },
            modal_unidade_edit: false,
            modal_competencia_edit: false,
            valor_provisorio_ementa: '',
            valor_provisorio_avaliacao: '',
            valor_provisorio_praticas: '',
            unidade_selecionada: '',
            competencia_selecionada: '',
            valor_provisorio_competencia: {
                nome: '',
                conhecimento: '',
                habilidade: '',
                atitude: '',
            },
            options_modalidade: [
                { value: 'Presencial', text: 'Presencial' },
                { value: 'EAD', text: 'EAD' },
            ],
            options_semestres: [
                { value: '1', text: '1° Semestre' },
                { value: '2', text: '2° Semestre' },
                { value: '3', text: '3° Semestre' },
                { value: '4', text: '4° Semestre' },
                { value: '5', text: '5° Semestre' },
                { value: '6', text: '6° Semestre' },
                { value: '7', text: '7° Semestre' },
            ],
            tipo_bib_selecionado: 'bibliografia_basica',
            options_bibliografia: [
                { value: 'bibliografia_basica', text: 'Básica' },
                { value: 'bibliografia_complementar', text: 'Complementar' },
            ],
            array_bibi_provisorio: '',
            usuario_perfil: ''
        }
    },
    computed: {
        bibliogafias_separadas_array(){
            let array = this.array_bibi_provisorio.split('2022.')
            array.splice(-1)
            array = array.map(x => x + '2022.')
            return array
        },
        soma_carga_unidades_curriculares: function(){
            let soma = 0
            if(this.value){
                this.value.forEach( x => soma = soma + parseInt(x.carga_horaria))
            }     
            return soma
        },

        num_unidades_curriculares: function(){
            // console.log(this.value)
            if (this.value){
                return this.value.length
            } else {
                return 0
            }
        },

        valor_formatado_unidades_tabela: function(){
            var groupBy = function(xs, key) {
                return xs.reduce(function(rv, x) {
                    (rv[x[key]] = rv[x[key]] || []).push(x);
                    return rv;
                }, {});
            };
            
            if(this.value){
                let valores_agrupados = groupBy(this.value, 'semestre')
                let valores_tabela = []
                Object.entries(valores_agrupados).forEach(x => {
                    valores_tabela.push({semestre_num: x[0], unidades: x[1]})
                })
                return valores_tabela 
            } else {
                return false
            }
        },

        sortTabela(){
            function compare(a, b) {
                if (a.semestre_num < b.semestre_num)
                    return -1;
                if (a.semestre_num > b.semestre_num)
                    return 1;
                return 0;
            }

            return this.teste_tabela.slice().sort(compare)
        }
    },
    methods: {
        sum_semestre_carga_horaria(semestre){
            // console.log('unidade: ', semestre)
            let b = 0
            semestre.unidades.forEach(x => b += parseInt(x.carga_horaria))
            return b
        },

        // Unidade Curricular
        resetModalUnidade() {
            // Volta o modal ao seu valor inicial ou ao novo valor recebido
            this.valor_provisorio_unidade = { nome_unidade: '', modalidade: '', carga_horaria: 0, semestre: '', competencias: [], praticas: '', avaliacao: '', bibliografia_basica: [], bibliografia_complementar: [] }
        },

        modalNovaUnidade(){
            this.modal_unidade_edit = false
            this.$bvModal.show('modal-add-unidade')
        },

        addUnidade(){
            let new_array = {
                nome_unidade: this.valor_provisorio_unidade.nome_unidade,
                modalidade: this.valor_provisorio_unidade.modalidade,
                carga_horaria: this.valor_provisorio_unidade.carga_horaria,
                semestre: this.valor_provisorio_unidade.semestre,
                competencias: [],
                praticas: '',
                avaliacao: '',
                bibliografia_basica: [],
                bibliografia_complementar: []
            }

            let obj_firebase
            obj_firebase = this.value
            obj_firebase.push(new_array)
            console.log(obj_firebase)

            console.log(this.id_curso)
            db.collection('/nadir').doc(this.id_curso)
            .update({'formulario_especifico.item_4_2_1': obj_firebase})
            .then(() => {
                this.$emit('valorEmitido', this.valor_provisorio)
                this.$bvModal.hide('modal-add-unidade')
                this.resetModalUnidade()
            })
            .catch((error) => {
                alert('Erro, verifique o console')
                console.error("Error updating document: ", error);
            });
        },

        editUnidade(id_unidade){
            this.modal_unidade_edit = true
            this.unidade_selecionada = id_unidade
            this.$bvModal.show('modal-add-unidade')
            this.valor_provisorio_unidade.nome_unidade = this.value[id_unidade].nome_unidade,
            this.valor_provisorio_unidade.carga_horaria = this.value[id_unidade].carga_horaria
            this.valor_provisorio_unidade.semestre = this.value[id_unidade].semestre
            this.valor_provisorio_unidade.modalidade = this.value[id_unidade].modalidade
        },

        deleteEditUnidade(){
            if(confirm('Tem certeza que desejas excluir a unidade: ' + this.value[this.unidade_selecionada].nome_unidade + ' ? Todo o preenchimento será perdido.')){
                this.value.splice(this.unidade_selecionada, 1)

                db.collection('/nadir').doc(this.id_curso)
                .update({'formulario_especifico.item_4_2_1': this.value})
                .then(() => {
                    this.$emit('valorEmitido', '')
                    this.$bvModal.hide('modal-add-unidade')
                    this.resetModalUnidade()
                })
                .catch((error) => {
                    alert('Erro, verifique o console')
                    console.error("Error updating document: ", error);
                });
            }
        },

        salvarEditUnidade(){
            // console.log('Item a ser alterado:', this.value[this.unidade_selecionada],this.unidade_selecionada)
            // console.log('Item a ser alterado:', this.value)

            let obj_firebase
            obj_firebase = this.value
            obj_firebase[this.unidade_selecionada].nome_unidade = this.valor_provisorio_unidade.nome_unidade
            obj_firebase[this.unidade_selecionada].carga_horaria = this.valor_provisorio_unidade.carga_horaria
            obj_firebase[this.unidade_selecionada].semestre = this.valor_provisorio_unidade.semestre
            obj_firebase[this.unidade_selecionada].modalidade = this.valor_provisorio_unidade.modalidade
            console.log(obj_firebase)

            db.collection('/nadir').doc(this.id_curso)
            .update({'formulario_especifico.item_4_2_1': this.value})
            .then(() => {
                this.$emit('valorEmitido', obj_firebase)
                this.$bvModal.hide('modal-add-unidade')
                this.resetModalUnidade()
            })
            .catch((error) => {
                alert('Erro, verifique o console')
                console.error("Error updating document: ", error);
            });
        },

        // Competências
        resetModalCompetencia() {
            // Volta o modal ao seu valor inicial ou ao novo valor recebido
            this.valor_provisorio_competencia = { nome: '', conhecimento: '', habilidade: '', atitude: '' }
        },

        // Competências
        resetModalBib() {
            // Volta o modal ao seu valor inicial ou ao novo valor recebido
            this.valor_provisorio_unidade.bibliografia_basica = ''
            this.valor_provisorio_unidade.bibliografia_complementar = ''
        },
  
        modalNovaCompetencia(id_unidade){
            this.modal_competencia_edit = false
            console.log(id_unidade)
            this.unidade_selecionada = id_unidade
            console.log(this.value[id_unidade])
            this.$bvModal.show('modal-add-competencia')
        },

        addCompetencia(){
            
            // console.log(this.value[this.unidade_selecionada])
            // console.log(this.value[this.unidade_selecionada].competencias)
            // this.value[this.unidade_selecionada].competencias.push(this.valor_provisorio_competencia)

            let new_array = {
                nome: this.valor_provisorio_competencia.nome,
                conhecimento: this.valor_provisorio_competencia.conhecimento,
                habilidade: this.valor_provisorio_competencia.habilidade,
                atitude: this.valor_provisorio_competencia.atitude,
            }

            if(!this.value[this.unidade_selecionada].competencias){
                this.value[this.unidade_selecionada].competencias = []
            } else{
                this.value[this.unidade_selecionada].competencias.push(new_array)
            }
            
            console.log(this.value)

            db.collection('/nadir').doc(this.id_curso)
            .update({'formulario_especifico.item_4_2_1': this.value})
            .then(() => {
                this.$emit('valorEmitido', this.valor_provisorio)
                this.$bvModal.hide('modal-add-competencia')
                this.resetModalCompetencia()
                this.modal_competencia_edit = false
            })
            .catch((error) => {
                alert('Erro, verifique o console')
                console.error("Error updating document: ", error);
            });
        },

        editCompetencia(id_unidade, id_competencia){
            this.modal_competencia_edit = true
            this.unidade_selecionada = id_unidade
            this.competencia_selecionada = id_competencia
            this.$bvModal.show('modal-add-competencia')
            this.valor_provisorio_competencia.nome = this.value[id_unidade].competencias[id_competencia].nome,
            this.valor_provisorio_competencia.conhecimento = this.value[id_unidade].competencias[id_competencia].conhecimento
            this.valor_provisorio_competencia.habilidade = this.value[id_unidade].competencias[id_competencia].habilidade
            this.valor_provisorio_competencia.atitude = this.value[id_unidade].competencias[id_competencia].atitude
        },

        salvarCompetencia(){
            let id_unidade = this.unidade_selecionada
            let id_competencia = this.competencia_selecionada
            // console.log(id_unidade, id_competencia)
            // console.log(this.value)

            this.value[id_unidade].competencias[id_competencia] = this.valor_provisorio_competencia

            db.collection('/nadir').doc(this.id_curso)
            .update({'formulario_especifico.item_4_2_1': this.value})
            .then(() => {
                this.$emit('valorEmitido', this.valor_provisorio_competencia)
                this.$bvModal.hide('modal-add-competencia')
                this.resetModalCompetencia()
            })
            .catch((error) => {
                alert('Erro, verifique o console')
                console.error("Error updating document: ", error);
            });
        },

        deleteEditCompetencia(){
            if(confirm('Tem certeza que desejas excluir a competencia: ' + this.value[this.unidade_selecionada].competencias[this.competencia_selecionada].nome + ' ? Todo o preenchimento será perdido.')){
                console.log('unidade:', this.unidade_selecionada)
                console.log('cometencia:', this.competencia_selecionada)
                this.value[this.unidade_selecionada].competencias.splice(this.competencia_selecionada, 1)
                console.log(this.value[this.unidade_selecionada].competencias[this.competencia_selecionada])

                db.collection('/nadir').doc(this.id_curso)
                .update({'formulario_especifico.item_4_2_1': this.value})
                .then(() => {
                    this.$emit('valorEmitido', '')
                    this.$bvModal.hide('modal-add-competencia')
                    this.resetModalCompetencia()
                })
                .catch((error) => {
                    alert('Erro, verifique o console')
                    console.error("Error updating document: ", error);
                });
            }
        },

        // Bibliografia
        modalNovaBib(id_unidade){
            console.log(id_unidade)
            this.unidade_selecionada = id_unidade
            // console.log(this.value[id_unidade])
            this.$bvModal.show('modal-add-bibliografia')
            console.log(this.value[id_unidade])
            this.valor_provisorio_unidade.bibliografia_basica = this.value[id_unidade].bibliografia_basica
            this.valor_provisorio_unidade.bibliografia_complementar = this.value[id_unidade].bibliografia_complementar
        },

        addBibliografia(){

            console.log('unidade selecionada', this.unidade_selecionada)
            console.log('Basica', this.value[this.unidade_selecionada].bibliografia_basica)

            this.value[this.unidade_selecionada].bibliografia_basica = this.valor_provisorio_unidade.bibliografia_basica
            this.value[this.unidade_selecionada].bibliografia_complementar = this.valor_provisorio_unidade.bibliografia_complementar


            db.collection('/nadir').doc(this.id_curso)
            .update({'formulario_especifico.item_4_2_1': this.value})
            .then(() => {
                this.$emit('valorEmitido', this.valor_provisorio_unidade)
                this.$bvModal.hide('modal-add-bibliografia')
                this.resetModalCompetencia()
            })
            .catch((error) => {
                alert('Erro, verifique o console')
                console.error("Error updating document: ", error);
            });
        },

        separarPushBib(){
            this.bibliogafias_separadas_array.forEach(x => this.value[this.unidade_selecionada][this.tipo_bib_selecionado].push(x)) 
            this.array_bibi_provisorio = ''
        },

        // Ementa
        resetModalEmenta(){
            // Volta o modal ao seu valor inicial ou ao novo valor recebido
            this.valor_provisorio_ementa = this.value[this.unidade_selecionada].ementa
        },

        modalEmenta(id_unidade){
            this.unidade_selecionada = id_unidade
            this.$bvModal.show('modal-edit-ementa')
        },

        editarEmenta(){
            let id_unidade = this.unidade_selecionada
            this.value[id_unidade].ementa = this.valor_provisorio_ementa
            let path = {[`formulario_especifico.item_4_2_1`]: this.value}
            
            console.log('end:', path)
            db.collection('/nadir').doc(this.id_curso)
            .update(path)
            .then(() => {
                this.$emit('valorEmitido', this.value)
                this.$bvModal.hide('modal-edit-ementa')
                this.resetModalEmenta()
            })
            .catch((error) => {
                alert('Erro, verifique o console')
                console.error("Error updating document: ", error);
            });
        },

        // Avaliação
        resetModalAvaliacao(){
            // Volta o modal ao seu valor inicial ou ao novo valor recebido
            this.valor_provisorio_avaliacao = this.value[this.unidade_selecionada].avaliacao
        },

        modalAvaliacao(id_unidade){
            this.unidade_selecionada = id_unidade
            this.$bvModal.show('modal-edit-avaliacao')
        },

        editarAvaliacao(){
            let id_unidade = this.unidade_selecionada
            this.value[id_unidade].avaliacao = this.valor_provisorio_avaliacao
            let path = {[`formulario_especifico.item_4_2_1`]: this.value}
            
            console.log('end:', path)
            db.collection('/nadir').doc(this.id_curso)
            .update(path)
            .then(() => {
                this.$emit('valorEmitido', this.value)
                this.$bvModal.hide('modal-edit-avaliacao')
                this.resetModalAvaliacao()
            })
            .catch((error) => {
                alert('Erro, verifique o console')
                console.error("Error updating document: ", error);
            });
        },

        // praticas
        resetModalPraticas(){
            // Volta o modal ao seu valor inicial ou ao novo valor recebido
            this.valor_provisorio_praticas = this.value[this.unidade_selecionada].praticas
        },

        modalPraticas(id_unidade){
            this.unidade_selecionada = id_unidade
            this.$bvModal.show('modal-edit-praticas')
        },

        editarPraticas(){
            let id_unidade = this.unidade_selecionada
            this.value[id_unidade].praticas = this.valor_provisorio_praticas
            let path = {[`formulario_especifico.item_4_2_1`]: this.value}
            
            console.log('end:', path)
            db.collection('/nadir').doc(this.id_curso)
            .update(path)
            .then(() => {
                this.$emit('valorEmitido', this.value)
                this.$bvModal.hide('modal-edit-praticas')
                this.resetModalPraticas()
            })
            .catch((error) => {
                alert('Erro, verifique o console')
                console.error("Error updating document: ", error);
            });
        },

        


    },
    created(){
        console.log('Valor recebido pelo componente', this.value)
    },
    mounted(){
        this.usuario_perfil = localStorage.perfil
    }
}
</script>

<style scoped>
hr{
    width: -webkit-fill-available
}

.competencia-card{
    background-color: #f3d98a;
    padding: 10px;
    border-radius: 7px
}

.competencia-card > p {
    margin-bottom: unset
}

.row{
    --bs-gutter-x: 0 !important
}
</style>